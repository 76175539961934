import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'

import {
  AvatarBox,
  Avatar,
  Wrapper,
  Spacer,
  LinkBase,
} from '../components/atoms'

import {
  SectionTitle,
  SectionInfo,
  BlockTitle,
  TextBase as Text,
  ContainerFull,
} from '../components/blocks'

import { Heading, Container } from 'theme-ui'
import { Box, Flex } from 'rebass'

import CTABlock from '../components/contact'

export const query = graphql`
  query TeamPageQuery {
    profiles: allSanityAuthor(filter: { slug: { current: { ne: null } } }) {
      edges {
        node {
          id
          name
          image {
            asset {
              fixed(width: 180, height: 180) {
                width
                height
                src
              }
            }
          }
          role
          _rawBio
          slug {
            current
          }
        }
      }
    }
  }
`

/**
 * Team Item
 * @param {*} members
 */
const TeamItem = ({ name, role, id, team, _rawBio, image }) => (
  <Box width={[1, 1 / 2, 1 / 2]} mb={6}>
    <Flex width={1} px={2} pl={0}>
      <AvatarBox width={4 / 12}>
        <Avatar
          width={image.asset.fixed.width}
          height={image.asset.fixed.height}
          src={image.asset.fixed.src}
        />
      </AvatarBox>
      <Box ml={4} mt={[0, 1, 5]} width={8 / 12}>
        <LinkBase to={`/profile/${id}`}>
          <Text variant="text.blockName">{name}</Text>
        </LinkBase>
        <Text variant="text.personName">{role}</Text>
        {/* <Text mt={3} sx={{ fontSize: 1 }}>
          {_rawBio[0].children[0].text}
        </Text> */}
      </Box>
    </Flex>
  </Box>
)

/**
 * Team Page
 * @param {*} members
 */

const TeamPage = (props) => {
  const { data, errors } = props

  if (errors) throw errors

  const postEdges = (data && data.profiles && data.profiles.edges) || []

  return (
    <Layout>
      <SEO
        title={'Our Team'}
        description="Our team of front-end engineers and design consultants"
      />
      <ContainerFull variant="styles.bigcard" mb={4}>
        <Spacer>
          <Box width={[1, 1 / 2, 6 / 12]} pt={6} pb={4} pl={[4, 2, 0]}>
            <Text variant="text.pageTitle">About</Text>
            <Box pt={2} pb={4}>
              <Text variant="text.pageDesc">
                We are a strategic, creative studio based out of Bangalore. Our
                team works with founders and product teams to ideate, design and
                build quality digital products and brand experiences.
              </Text>
            </Box>
          </Box>
        </Spacer>
      </ContainerFull>
      <ContainerFull variant="styles.bigcard" mb={4}>
        <Box mt={4} mb={6} mt={4} pl={[4, 2, 0]}>
          <Text variant="text.secTitle">People</Text>
          <Flex mx={0} flexWrap="wrap">
            {postEdges &&
              postEdges.length > 0 &&
              postEdges.map((node) => <TeamItem {...node.node} />)}
            {/* {profilesNodes && <TeamItem data={profilesNodes} />} */}
          </Flex>
        </Box>
      </ContainerFull>
      <CTABlock/>
    </Layout>
  )
}

export default TeamPage
